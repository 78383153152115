
          @import "~@/assets/css/_variables";
          @import "~@/assets/css/_sizes";
          @import "~@/assets/css/_sizesMobile";
        































.Input {
  width: 100%;
  height: 64px;
  background-color: #f0f3f6;
  border-radius: 20px;
  border: none;
  padding: 20px;
  font-family: 'EuclidCircularA-Regular', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1f2021;
  caret-color: #1eae8d;

  &:disabled {
    color: #999c9e;
  }
}

.placeholder-transparent::placeholder {
  color: rgba(0, 0, 0, 0.3);
  opacity: 1; /* Firefox */
}

.placeholder-transparent:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.3);
}

.placeholder-transparent::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.3);
}
