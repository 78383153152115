
          @import "~@/assets/css/_variables";
          @import "~@/assets/css/_sizes";
          @import "~@/assets/css/_sizesMobile";
        




































.LinkButton {
  display: block;
  padding: 20px;
  width: 100%;
  height: 64px;
  background: #1f2021;
  border-radius: 20px;
  font-family: 'EuclidCircularA-Regular', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #28e1b9;
  text-align: center;
  text-decoration: none;

  &.disabled {
    background: #eaecee;
    color: #999c9e;
    pointer-events: none;
  }
}
