@font-face {
    font-family: 'EuclidCircularA-Bold';
    src: url('EuclidCircularA-Bold-Web/EuclidCircularA-Bold-WebM.woff2') format('woff2'),
        url('EuclidCircularA-Bold-Web/EuclidCircularA-Bold-WebM.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'EuclidCircularA-Light';
    src: url('EuclidCircularA-Light-Web/EuclidCircularA-Light-WebM.woff2') format('woff2'),
    url('EuclidCircularA-Light-Web/EuclidCircularA-Light-WebM.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'EuclidCircularA-Medium';
    src: url('EuclidCircularA-Medium-Web/EuclidCircularA-Medium-WebM.woff2') format('woff2'),
    url('EuclidCircularA-Medium-Web/EuclidCircularA-Medium-WebM.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'EuclidCircularA-Regular';
    src: url('EuclidCircularA-Regular-Web/EuclidCircularA-Regular-WebM.woff2') format('woff2'),
    url('EuclidCircularA-Regular-Web/EuclidCircularA-Regular-WebM.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'EuclidCircularA-Semibold';
    src: url('EuclidCircularA-Semibold-Web/EuclidCircularA-Semibold-WebM.woff2') format('woff2'),
    url('EuclidCircularA-Semibold-Web/EuclidCircularA-Semibold-WebM.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

