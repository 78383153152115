
          @import "~@/assets/css/_variables";
          @import "~@/assets/css/_sizes";
          @import "~@/assets/css/_sizesMobile";
        

























































































.car-search {
  padding: 18px;

  @media (min-width: 769px) {
    padding: 10% 33%;
  }

  .logo-container {
    display: flex;
    align-items: center;
    gap: 6px;
  }

  .logo-icon {
    height: 32px;
    width: 32px;
  }

  .logo {
    width: 160px;
    height: 24px;
  }

  &__title {
    margin-top: 24px;
    font-size: 24px;

    @media (min-width: 769px) {
      text-align: center;
    }
  }

  .hint {
    font-size: 14px;
    line-height: 20px;

    color: #6d7275;
  }

  .car-number-input {
    margin-top: 20px;
  }

  .search-button {
    margin-top: 28px;
  }
}
